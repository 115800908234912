<template>
  <div>
    <b-card title="Biểu Đồ Chốt Đơn Nhân Viên">
      <line-chart
        :data="data"
        :height="100"
      />
    </b-card>
  </div>
</template>

<script>
import { BCol, BRow, BCard } from 'bootstrap-vue'
import LineChart from './components/LineChart.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    LineChart
  },
  created() {
    const arrs = [11, 23, 4 , 5 , 6, 7, 8, 9 , 4 , 1, 6,7,8,9,4,1,6,7,8,9,4,1,6,7,6,7,8,9,4]
    
    arrs.forEach((arr, k) => {
      this.data.labels[k] = k + 1

      this.data.datasets[0] = {
        label: 'Đã Xác Nhận',
        data: arrs,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      }
      this.data.datasets[1] = {
        label: 'Chưa Xác Nhận',
        data: arrs.map(item => item + 5),
        fill: false,
        borderColor: 'rgb(75, 2, 192)',
        tension: 0.1,
      }
      this.data.datasets[2] = {
        label: 'Thành Công',
        data: arrs.map(item => item + 15),
        fill: false,
        borderColor: 'rgb(188, 2, 192)',
        tension: 0.1,
      }
    })
  },
  data() {
    return {
      data: {
        labels: [],
        datasets: [{
          label: 'Đã Xác Nhận',
          data: [],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        }],
      },
    }
  },
}
</script>
